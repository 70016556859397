/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@import 'material-icons/iconfont/material-icons.css';

@import './theme/mk_variables';

h1{
    text-transform: uppercase;
    color: var(--ion-color-primary);
}
h2{
    color: black;
}
h3{
    text-transform: uppercase;
    color: var(--ion-color-secondary);
}
h4{
    text-transform: uppercase;
    color: black;
}
h5{
    text-transform: uppercase;
    color: var(--ion-color-secondary);
}
h6{
    text-transform: uppercase;
    color: var(--ion-color-primary);
}
ul, ol{
    color:black;
    li{
        color: black;
    }
}
ul {
    list-style: none;
  }
ul li::before {
    content: "\2022";
    // font-size: 36px;
    color: var(--ion-color-primary);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
a{
    &:hover{
        text-decoration: none;
    }
}

p{
    color: #000;
}

.fullheight{
    height: 100%;
}

.dcha{
    text-align: right;
}
.centro{
    text-align: center;
}
.izq{
    text-align: left;
}

.logo{
    text-align: center;
    margin-bottom:40px;
}


.formulario{
    ion-item{
        margin-bottom:20px;
    }
}

.interiorPage{
    background:$bg;
    --background:$bg;
}

ion-header{
    background:$bg;
    --background:$bg;
    &:after{
        display: none;
    }
    ion-toolbar{
        background:$bg;
        --background:$bg;
        --opacity: 1;
    }
}

.sidebar{
    ion-content{
        .logo{
            padding-top:20px;
        }
    }
}

.opcionesDestacadas{
    --ion-grid-padding:10px;
}
.buscadorWrapper{
    --ion-grid-padding:10px;
    ion-row{
        align-items: flex-end;
    }
}
.contenidoWrapper,
.tablaWrapper{
    --ion-grid-padding:10px;
    @media screen and (max-width:760px){
        --ion-grid-padding:0;
    }
    ion-card{
        border: none !important;
        box-shadow:none !important;
    }
}

.opcionesDestacadas {
    ion-col{
        margin-bottom:30px;
    }
    ion-card-title{
        color: $secondary;
        text-transform: uppercase;
        text-align: left;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        span{
            color:$primary;
            background-color:$bg_azul;
            border-radius: 5px 5px 5px 5px;
            -moz-border-radius: 5px 5px 5px 5px;
            -webkit-border-radius: 5px 5px 5px 5px;
            padding:5px;
            margin-right:10px;
        }
    }
    ion-card-content{
        color:black;
    }
}

ion-button.btnSecundario{
    --border-color: var(--ion-color-secondary);
    --color: var(--ion-color-primary);

    --background-hover: var(--ion-color-primary);
    --color-hover: white;
    --background-hover-opacity: 1;
    &:hover{
        --border-color: var(--ion-color-primary);
    }
}
ion-button.btnMenu{
    --border-color: var(--ion-color-secondary);
    --color: var(--ion-color-primary);

    --background-hover: var(--ion-color-primary);
    --color-hover: white;
    --background-hover-opacity: 1;
    margin: 0 0 15px 0;
    span.material-icons{
        margin-right:10px;
    }
    span.ion-text-left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    &.selected,
    &:hover{
        --border-color: var(--ion-color-primary);
        --background: var(--ion-color-primary);
        --color: white;
        --background-opacity: 1;
    }
}
ion-button.btnNuevoPaciente{
    --border-color: var(--ion-color-primary);
    --color: white;
    --background: var(--ion-color-primary);

    --background-hover: white;
    --color-hover: var(--ion-color-primary);
    --background-hover-opacity: 1;
    span.material-icons{
        margin-right:10px;
    }
    span.ion-text-left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
ion-button.btnQuickLink{
    --border-color: #ddd;
    --color: var(--ion-color-primary);
    --background:white;

    --background-hover: #ddd;
    --color-hover: var(--ion-color-primary);
    --background-hover-opacity: 1;
    margin:10px;
    span.material-icons{
        margin-right:10px;
    }
    span.ion-text-left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
ion-button.btnSimpleLink{
    --border-color: transparent;
    --color: var(--ion-color-primary);
    --background:white;

    --background-hover: #ddd;
    --color-hover: var(--ion-color-primary);
    --background-hover-opacity: 1;
    margin:10px;
    span.material-icons{
        margin-right:10px;
    }
    span.ion-text-left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
}
ion-button.btnLink{
    --border-color: transparent;
    --color: var(--ion-color-primary);

    --background-hover: #eee;
    --color-hover: var(--ion-color-primary);
    --background-hover-opacity: 1;
    margin: 0;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;

    @media screen and (max-width:1208px){
        height: 50px;
    }
    @media screen and (max-width:991px){
        height:auto
    }
    @media screen and (max-width:907px){
        height: 50px;
    }
    span.ion-text-left {
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width:1208px){
            flex-direction: column;
            justify-content: center;
        }
        @media screen and (max-width:991px){
            flex-direction: row;
            justify-content: flex-start;
        }
        @media screen and (max-width:907px){
            flex-direction: column;
            justify-content: center;
        }
        @media screen and (max-width:500px){
            flex-direction: row;
            justify-content: flex-start;
        }
        span.texto{
            text-decoration: underline;
            @media screen and (max-width:1106px){
                display: none;
            }
            @media screen and (max-width:991px){
                display: block;
            }
            @media screen and (max-width:805px){
                display: none;
            }
            @media screen and (max-width:760px){
                display: block;
            }

            // @media screen and (max-width:820px){
            //     display: none;
            // }
            // @media screen and (max-width:760px){
            //     display: block;
            // }
            // @media screen and (max-width:512px){
            //     display: none;
            // }
        }

    }
    span.material-icons{
        margin-right:10px;
        color: var(--ion-color-secondary);
        @media screen and (max-width:1208px){
            margin-right: 0;
            margin-bottom: 10px;
        }
        @media screen and (max-width:991px){
            margin-right: 10px;
            margin-bottom: 0;
        }
        @media screen and (max-width:907px){
            margin-right: 0;
            margin-bottom: 10px;
        }
        @media screen and (max-width:500px){
            margin-right: 10px;
            margin-bottom: 0;
        }

    }
    &.selected,
    &:hover{
        --border-color: transparent;
        --background: #eee;
        --color: var(--ion-color-primary);
        --background-opacity: 1;
        span.texto{
            text-decoration: none !important;
        }
    }
}
.sidebar{
    ion-list{
        padding:8px 20px;
    }
}

.pipe{
    font-size: 0;
    background: #DDD;
    width: 1px;
    height: 20px;
    display: inline-block;
}


/* TABLA */
table.datosTable {
    width:100%;
	border-collapse: collapse;

    /* Zebra striping */
    tr:nth-of-type(odd) {
        // background: #eee;
    }

    th {
        background: #D6F4F9;
        color: black;
        font-weight: 400;
        border-bottom:2px solid $primary;
        padding: 10px;
        text-align: left;
        &.centro{
            text-align: center;
        }
        &.dcha{
            text-align: right;
        }
        &.izq{
            text-align: left;
        }
    }

    tbody th {
        // border-right:2px solid $primary;
        border-bottom: 1px solid #DDD;
    }

    td {
        padding: 10px;
        border-bottom: 1px solid #DDD;
        text-align: left;
        color: black;
        // &.acciones{
        //     min-width:465px;
        //     @media screen and (max-width:1088px){
        //         min-width: auto;
        //     }
        //     @media screen and (max-width:907px){
        //         min-width: 465px;
        //     }
        //     @media screen and (max-width:820px){
        //         min-width: auto;
        //     }
        // }
        &.centro{
            text-align: center;
        }
        &.dcha{
            text-align: right;
        }
        &.izq{
            text-align: left;
        }
    }
}
/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
@media screen and (max-width: 760px)  {

	table.datosTable {
	  	width: 100%;
        display: block;


        /* Force table to not be like tables anymore */
        thead, tbody, th, td, tr {
            display: block;
        }

        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tr { border: 1px solid #ccc; }

        tbody th {
            border-bottom:2px solid $primary;
            border-right: none;
        }

        td {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #ddd;
            position: relative;
            // padding-left: 50%;
            text-align: left !important;
            padding-top:51px;

        }
        &.sin{
            td{
                padding-top:10px;
            }
        }

        // td:before {
        // 	/* Now like a table header */
        // 	position: absolute;
        // 	/* Top/left values mimic padding */
        // 	top: 6px;
        // 	left: 6px;
        // 	width: 45%;
        // 	padding-right: 10px;
        // 	white-space: nowrap;
        // 	/* Label the data */
        // 	content: attr(data-column);

        // 	color: #000;
        // 	font-weight: bold;
        // }
    }

}


.falseTh{
    position: absolute;
    background: #ddd;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 15px;
    padding: 10px;
    display: none;
    @media screen and (max-width: 760px)  {
        display: block;
    }
}
@media screen and (max-width: 500px)  {
    td.acciones{
        display: flex;
        flex-direction: column;
        .pipe{
            height:1px;
            width:100%;
            margin:10px 0;
        }
    }
}

/* FIN TABLA */
.mat-next {
    background-color:  var(--ion-color-primary);
    color: white;
    text-transform: uppercase;
    box-shadow:none !important;
}
.mat-prev {
    background-color:  white;
    color: var(--ion-color-primary);
    text-transform: uppercase;
    box-shadow:none !important;
}
.falseLabel{
    color: var(--ion-color-primary);
    font-size: 12px;
}

//input outline color
.mat-form-field-appearance-outline .mat-form-field-label-wrapper .mat-form-field-label mat-label {
    color: var(--ion-color-primary);
    // opacity: 1!important;
}
.mat-form-field-appearance-outline:hover .mat-form-field-outline-thick,
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
    color: var(--ion-color-primary);
}

.mat-form-field .mat-form-field-prefix{
    margin-right: 10px;
}

.mat-placeholder-required{
    color:$danger-color !important;
}

.resultado{
    padding:50px;
    .titulo{
        margin-bottom:30px;
    }
    &.ok{
        .titulo{
            font-size:34px;
            color: $secondary;
        }
    }
    &.ko{
        .titulo{
            font-size:34px;
            color: $danger-color;
        }
    }
}

//estilo campos autocompletados
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active { -webkit-box-shadow: 0 0 0 30px white inset !important; }

input:-webkit-autofill { -webkit-text-fill-color: black !important; }


.btnSimple{
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border:2px solid #ddd;
    border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
    background:transparent;
    text-transform: uppercase;
    padding:0 8px;
    height: 32px;
    font-size: 14px;
    letter-spacing: 0.84px;
    color: var(--ion-color-primary);
    text-decoration: none;
    font-weight: 500;
    &:hover{
        background-color:#ddd;
    }
}

.nbPaciente{
    font-size: 24px;
    color: var(--ion-color-primary);
    text-transform: uppercase;
    padding:8px 8px 8px 0;
}

.especialTitle{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin:0 16px;
}
.especialTitleContenido{
    display: flex;
    align-items: center;
    padding:20px;
}

.tabsContainer{
    --ion-grid-column-padding:0;
}


.tabContent{
    padding:20px;

}
.mano{
  cursor:pointer;
}
