/*----------------- VARIABLES -------------------------------*/
$primary: #004392;
$secondary: #31C6E2;

$bg: #f5f5f5;
$bg_azul: #C1EDF6;
// $naranja: #e77038;
// $gris: #7b7b7b;
// $azul_bg: #eaeff6;
// $azul_degradado: #7d9dc8;
// $bordes:#f4f4f4;
// $gris_bg: #F9F9F9;

$danger-color:#dc3545;
// $success-color:#28a745;

// $default-font: 'Muli', Arial, Helvetica, sans-serif;

// /*----------------- TIPOGRAFIA -------------------------------*/
// @import url('https://fonts.googleapis.com/css?family=Muli:300,400,700,900&display=swap&subset=latin-ext');
// *{
//     font-family: $default-font;
// }
// ion-content.web{
//     font-size: 16px;
// }
